import React, { Component } from "react";
import {
  Tab,
  Tabs,
  Grid,
  Cell,
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  CardMenu,
  IconButton,
} from "react-mdl";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };

    this.chatApplication = () => {
      window.location.assign("https://rupakkhaber.netlify.app/");
    };
    this.calculator = () => {
      window.location.assign(
        "https://digital-administrationof-lalitpur-muncipalty.netlify.app/"
      );
    };
    this.portfolio = () => {
      window.location.assign("https://gihubfinder11.netlify.app/");
    };
    this.quiz = () => {
      window.location.assign("https://lets-play-quiz-rai.netlify.app/");
    };
    this.ecommerce = () => {
      window.location.assign("https://github.com/ismail5g/EasyBazar");
    };
    this.leavemangement = () => {
      window.location.assign("https://github.com/ismail5g/Leave-Management");
    };
    this.crudapplication = () => {
      window.location.assign(
        "https://github.com/ismail5g/BookListCrud_MVC-Asp.Net-Core"
      );
    };
    this.facebook = () => {
      window.location.assign(
        "https://github.com/ismail5g/Facebook-Education-Perpose"
      );
    };
    this.toggleCategories = () => {
      if (this.state.activeTab === 0) {
        return (
          <div className="projects-grid">
            {/* Chat-Application */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/News.jpg) center / cover",
                }}
              >
                News Portal
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is a chat Application developed on a running course from
                UDEMY.
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.chatApplication.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button
                  raised
                  accent
                  ripple
                  style={{ margin: "0 10px" }}
                  onClick={this.chatApplication.bind(this)}
                >
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
            {/* Calculator */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/Digital.png) center / cover",
                }}
              >
                Digital Adminstration System
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is a first REACT app i developed. Which is also my summer
                project.
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.calculator.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button
                  raised
                  accent
                  ripple
                  style={{ margin: "0 10px" }}
                  onClick={this.calculator.bind(this)}
                >
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
            {/* Portfolio */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/quiz.png) center / cover",
                }}
              >
                Quiz Project
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is a chat Application developed on a running course from
                college REACT tranning.
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.portfolio.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button
                  raised
                  accent
                  ripple
                  style={{ margin: "0 10px" }}
                  onClick={this.portfolio.bind(this)}
                >
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>

            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/Githubfinder.PNG) center / cover",
                }}
              >
                Github Finder
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is a chat Application developed on a running course from
                UDEMY.This application helps to find people on github,
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.portfolio.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button
                  raised
                  accent
                  ripple
                  style={{ margin: "0 10px" }}
                  onClick={this.portfolio.bind(this)}
                >
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>

            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/blog.JPG) center / cover",
                }}
              >
                Blog App
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is a full stack Application developed on a running course
                from UDEMY.In this application people can write blog and also
                update and delete their blog.
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  // onClick={this.portfolio.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button raised accent ripple style={{ margin: "0 10px" }}>
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
          </div>
        );
      } else if (this.state.activeTab === 1) {
        return (
          <div className="projects-grid">
            {/* Ecom-Application */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/cms.png) center / cover",
                }}
              >
                Music Info
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is nepali band informative website.This is a fully dynamic
                website.
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.ecommerce.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button raised accent ripple style={{ margin: "0 10px" }}>
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
            {/* Leave-management */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/zeromiles.JPG) center / cover",
                }}
              >
                Zeromiles website
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is dynamic website system developed for Zeromiles
                construction company .
              </CardText>
              <CardActions border>
                {/* <Button
                  style={{ margin: "0 10px" }}
                  raised
                  ripple
                  primary
                  onClick={this.leavemangement.bind(this)}
                >
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button raised accent ripple style={{ margin: "0 10px" }}>
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
            {/* Ecom-Application */}
          </div>
        );
      }
      if (this.state.activeTab === 2) {
        return (
          <div>
            {/* Store-Procedure */}
            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/ContactKeeper.PNG) center / cover",
                }}
              >
                Contact Keeper
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is simple contact keeper system.
              </CardText>
              <CardActions border>
                {/* <Button style={{ margin: "0 10px" }} raised ripple primary>
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button raised accent ripple style={{ margin: "0 10px" }}>
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>

            <Card shadow={5} className="project-item">
              <CardTitle
                style={{
                  color: "#333",
                  height: "180px",
                  background: "url(images/blog.JPG) center / cover",
                }}
              >
                Blog App
              </CardTitle>
              <CardText style={{ textAlign: "justify" }}>
                This is simple full stack application Blog application. In this
                people can post their blog and also can see others blogs.
              </CardText>
              <CardActions border>
                {/* <Button style={{ margin: "0 10px" }} raised ripple primary>
                  <i className="fa fa-github" aria-hidden="true" /> Github
                </Button> */}
                <Button raised accent ripple style={{ margin: "0 10px" }}>
                  <i className="fa fa-desktop" aria-hidden="true" /> Live Demo
                </Button>
              </CardActions>
              <CardMenu style={{ color: "#fff" }}>
                <IconButton name="share" />
              </CardMenu>
            </Card>
          </div>
        );
      }
    };
  }

  render() {
    return (
      <div className="category-tabs">
        <Tabs
          activeTab={this.state.activeTab}
          onChange={(tabId) => this.setState({ activeTab: tabId })}
          ripple
        >
          <Tab>React JS</Tab>
          <Tab>PHP</Tab>
          <Tab>Node</Tab>
        </Tabs>
        <Grid>
          <Cell col={12}>
            <div className="content">{this.toggleCategories()}</div>
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default Projects;
