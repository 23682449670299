import React, { Component } from "react";
import { Grid, Cell } from "react-mdl";
import Type from "./Type";

class Homepage extends Component {
  render() {
    return (
      <div style={{ width: "100%", margin: "auto" }}>
        <Grid className="homepage-grid">
          <Cell col={12}>
            <div className="profile-img-border">
              <img
                className="profile-img"
                src="images/DSC_0591.JPG"
                alt="Ismail"
              />
            </div>
            <div>
              <div className="type-ani">
                <Type />
              </div>

              <hr />
              <p style={{ color: "#fff" }}>
                HTML | CSS | BOOTSTRAP | PHP | MYSQL | REACT | NODE |
              </p>
              <div className="social-links">
                {/* LinkedIn */}
                <a
                  href="https://www.facebook.com/rupak.rai.5832"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  <i
                    className="fa fa-facebook-official"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                {/* LinkedIn */}
                <a
                  href="https://twitter.com/RupakRa38723592"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  <i
                    className="fa fa-twitter-square"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                {/* LinkedIn */}
                <a
                  href="https://github.com/rupakrai11"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  <i
                    className="fa fa-github-square"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                {/* LinkedIn */}
                <a
                  href="https://www.linkedin.com/in/rupak-rai-235a7b176//"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  <i
                    className="fa fa-linkedin-square"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default Homepage;
