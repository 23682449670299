import React, { Component } from "react";
import { Grid, Cell } from "react-mdl";
import Education from "./Education";
import Experience from "./Experience";
import Skills from "./Skills";

class Resume extends Component {
  render() {
    return (
      <div>
        <Grid className="resume-gird">
          <Cell className="resume-right-col" col={8}>
            <h2>Education</h2>
            <Education
              startYear={2017}
              endYear={2021}
              schoolName={"KIST college of management"}
              degreeName={"Bachelor's in information management"}
            />
            <hr style={{ borderTop: "3px solid #e22947" }} />
            <h2>Experience</h2>
            <Experience
              startYear={"May 2021"}
              endYear={"July 2021"}
              jobName={"Internship"}
              jobDesc={
                "It was great oppertunity for me to learn React js with exprt software developer."
              }
            />
            {/* <Experience
              startYear={"Spring 2019"}
              endYear={"Fall 2019"}
              jobName={"Trainer For Junior Solver"}
              jobDesc={"Teach C Programming Language 1st year students"}
            />
            <Experience
              startYear={"Spring 2020"}
              endYear={"Summer 2020"}
              jobName={"ACM Problem Setter"}
              jobDesc={
                "Problem setter on DIU Take OFF Programming Contest And Several others contest on Daffodil"
              }
            /> */}

            <hr style={{ borderTop: "3px solid #e22947" }} />
            <h2>Skills</h2>
            <Skills skill={"HTML/CSS"} progress={80} />
            <Skills skill={"ReactJS"} progress={80} />
            <Skills skill={"PHP CORE"} progress={70} />
            <Skills skill={"NODE JS"} progress={50} />
          </Cell>

          <Cell col={4}>
            <div style={{ textAlign: "center" }}>
              <img
                src="images/re.png"
                alt="avatar"
                style={{ height: "250px" }}
              />
            </div>

            <h2 style={{ paddingTop: "1em" }}>Rupak Rai</h2>
            <h4 style={{ color: "grey" }}>MERN STACK DEVELOPER</h4>
            <hr style={{ borderTop: "2px solid #833fb2", width: "50%" }} />
            <p style={{ textAlign: "justify" }}>
              This is Rupak Rai. Now i'm a final year student of Bachelor's of
              Information Management at KIST college of management. I liked to
              solve a new real world problem by coding. I have been very
              passionate about computers and programming since my university
              life. I dream to be an expert software engineer so that I can
              build professional and useful software that has business value.
            </p>
            <hr style={{ borderTop: "2px solid #833fb2", width: "50%" }} />
            <h5>Address</h5>
            <p>Koteshwor-Kathmandu</p>
            <h5>Phone</h5>
            <p>9808654654</p>
            <h5>Email</h5>
            <p>rairupak81@gmail.com</p>
            <h5>Web</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              href="https://talent-programmer.blogspot.com"
            >
              My Programming Blog
            </a>
            {/* <h5>Download CV</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              href="https://drive.google.com/file/d/1Cpf_RC5pRDkje-dyRnmhld3VNP4xJ0hR/view?usp=sharing"
            >
              My Update CV
            </a> */}
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default Resume;
